import { DocumentType, SchemaType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '../../../containers/Admin/props';
import IconEye from '../../../icons/Eye';
import IconEyeSlash from '../../../icons/EyeSlash';
import ListItem from './index';

const ListItemSchema: FC<ListItemProps<SchemaType>> = ({
  docId,
  index,
  model,
  onClick,
}) => {
  const [doc, setDoc] = useState<DocumentType<SchemaType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem doc={doc} index={index} onClick={onClick}>
      <div className="ml-3 flex items-center space-x-3">
        {!doc.hidden && <IconEye />}
        {doc.hidden && <IconEyeSlash />}

        <strong>{doc.name}</strong>
      </div>
    </ListItem>
  );
};

export default ListItemSchema;
